#nprogress {
  pointer-events: none;
}


#nprogress .bar {
  background: #a81e1e;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.125rem;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 6.25rem;
  height: 100%;
  box-shadow: 0 0 0.625rem #a81e1e, 0 0 0.3125rem #a81e1e;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0rem, -0.25rem);
  -ms-transform: rotate(3deg) translate(0rem, -0.25rem);
  transform: rotate(3deg) translate(0rem, -0.25rem);
}